import React from "react";
import Layout from "../../../components/Layout";
import Episode from "../../../components/Podcast/Episode";
import podcastEpisodes, { pageKeywords } from "../../../utils/podcastEpisodes";

export default ({ location }) => {
  const lang = "ja";

  // Page setting
  const episodeNo = 41;
  const pageImage = "";
  const episode = podcastEpisodes.find(ep => ep.id === episodeNo);
  const episodeContent = (
    <>
      <ul className="basicList">
        <li>イントロを入れてみました</li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=9pjrl3ORqXM"
            target="_blank"
            rel="noopener noreferrer"
          >
            AR対応したワイン 19 Crimes
          </a>
        </li>
        <li>
          <a
            href="https://www.ptc.com/ja/case-studies/augmented-reality-19-crimes-wine"
            target="_blank"
            rel="noopener noreferrer"
          >
            PTC お客様事例
          </a>
        </li>
        <li>専用アプリをダウンロードするのはハードルが高い</li>
        <li>ダッチオーブンの素材とサイズは？</li>
        <li>丸鶏ってどこで買える？</li>
        <li>ダッチオーブンはキャンプだけのものではない</li>
        <li>ダッチオーブンの歴史</li>
        <li>
          <a
            href="https://honto.jp/netstore/pd-book_01356680.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            ダッチ・オーヴン カウボーイが教える万能鉄鍋料理
          </a>
        </li>
        <li>煮る、焼く、揚げる、蒸すもできる万能鍋</li>
        <li>鉄製はメンテナンスが面倒くさい</li>
        <li>1つ買うなら何製の鍋にする？</li>
        <li>キャンプ製品をいろいろ作ってるユニフレーム</li>
      </ul>
    </>
  );

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={episode.title}
      pageSlug={episode.slug}
      pageKeywords={pageKeywords}
      pageDescription={episode.description}
      pageImage={pageImage}
    >
      <Episode
        episodeNo={episodeNo}
        episodeTitle={episode.title}
        episodeDescription={episode.description}
        episodeContent={episodeContent}
        episodeURL={episode.embed}
      />
    </Layout>
  );
};
